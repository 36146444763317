import { AbstractControl } from "@angular/forms";

import { camelCase, Dictionary, mapKeys, omitBy, reduce } from "lodash";
import { DateTime } from "luxon";

export function getNormalizedParams(params: {
  [key: string]: any;
}): Dictionary<any> {
  return mapKeys(params, (value: any, key: string) => {
    return camelCase(key);
  });
}
export function formatFullClaimNumber(claimNumber: string = ""): string {
  const formattedClaimNumber = claimNumber.replace(/\D/g, "");

  if (formattedClaimNumber.length === 9) {
    const centuryThresholdYear = +DateTime.utc().toFormat("yy") + 1;
    const claimYear = parseInt(formattedClaimNumber.substring(0, 2), 10);

    if (claimYear <= centuryThresholdYear) {
      return `20${formattedClaimNumber}`;
    }

    return `19${formattedClaimNumber}`;
  }

  return formattedClaimNumber;
}

export function formatDisplayClaimNumber(claimNumber: string = ""): string {
  const formattedClaimNumber = claimNumber.replace(/\D/g, "");
  if (!formattedClaimNumber) {
    return "";
  }

  let startPosition = 0;

  if (formattedClaimNumber.length === 11) {
    startPosition = 2;
  } else if (formattedClaimNumber.length === 13) {
    startPosition = 4;
  }

  return `${formattedClaimNumber.slice(
    startPosition,
    startPosition + 2
  )}-${formattedClaimNumber.slice(startPosition + 2)}`;
}

export function toggleControlDisabled(
  control: AbstractControl,
  condition: boolean,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  newValue?: any
): void {
  if (condition) {
    control.disable({ emitEvent: false });

    if (newValue !== undefined) {
      control.setValue(newValue, { emitEvent: false });
    }
  } else {
    control.enable({ emitEvent: false });
  }
}

/*
  same comparator function implementation as default from mat-table sortData function
  https://github.com/angular/material2/blob/962e303d8227426c306016ea06f937b4b6b43bd4/src/lib/table/table-data-source.ts
*/
export function compare(
  a: number | string | null,
  b: number | string | null,
  isAsc: boolean
): number {
  // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
  // one value exists while the other doesn't. In this case, existing value should come first.
  // This avoids inconsistent results when comparing values to undefined/null.
  // If neither value exists, return 0 (equal).
  let comparatorResult = 0;
  if (a != null && b != null) {
    // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
    if (a > b) {
      comparatorResult = 1;
    } else if (a < b) {
      comparatorResult = -1;
    }
  } else if (a != null) {
    comparatorResult = 1;
  } else if (b != null) {
    comparatorResult = -1;
  }

  return comparatorResult * (isAsc ? 1 : -1);
}

export function deepCopy<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export function isIE(): boolean {
  return !!(document as any)["documentMode"];
}

export const buildQueryParams = (queryStringParams: {
  [key: string]: string | number | object | undefined | boolean;
}): string => {
  const extantParams = omitBy(
    queryStringParams,
    (value: string | number | object | undefined | boolean) => {
      return typeof value === "undefined";
    }
  );
  const paramKeys = Object.getOwnPropertyNames(extantParams);

  return reduce(
    extantParams,
    (
      accumulator: string,
      value: string | object | number | boolean,
      key: string
    ) => {
      const paramIndex: number = paramKeys.indexOf(key);
      const prefix = paramIndex === 0 ? "?" : "&";
      const paramValue =
        typeof value === "string" ? value : JSON.stringify(value);
      return `${accumulator}${prefix}${key}=${paramValue}`;
    },
    ""
  );
};

export const numberify: (input: string | number) => string | number = (
  input: string | number
) => {
  if (typeof input === "string") {
    return parseInt(input, 10);
  }

  return input;
};
